const codeText = `
Processes: 202 total, 6
virsion. 2.0.24

# WELCOME_TO_MY_PORTFOLIO
# GET /MyInfo.me
def MyInfo() {
  @myname is Kaito_Matsuda
  Born: >2004
  From: Japan/Aichi/Gamagori
}

GET. Instagram.Account -> @kaito_matsuda_{
    For (Requests.send(DM) on Instagram!!
}

# DEFINE
MY_WORK = CREATING_VIDEOS && TAKING_PHOTO && WRITING_CODE

for (MY_WORK) {
  ProducingShortFilms (
    music _videos,
    corporate_projects, etc.
  ),

  TakingPhotos (
    ArtistPhoto,
    PortraitPhoto,
    AlbumJacket, etc.
  ),

  AND_I_AM_WRITING_CODE(() => {
    FRONTEND {
      React.js,
    },

    BACKEND {
      Python,
    },
  })

  USING_GEAR {
    Camera: Lumix s5iix
    Lens: Sigma 28mm f1.4 DG HSM Art,
    Fender: JV Modified '50s Stratocaster HSS🎸,
  }
} !around(2020)
`;

export default codeText;
