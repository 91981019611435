import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/footer.css'


const Footer = () => {
    return(
        <footer>
            <p className="copyright">Copyright © Kaito Matsuda All Rights Reserved.</p>
        </footer>
    );
};

export default Footer;