import React from 'react';
// import Header from './components/Header';

const About = () => {
  return (
    <div>
      {/* <Header /> */}
      <h2>About</h2>
    </div>
  );
};

export default About;